















































  import { UserModel, UserType } from '@/store/auth/types';
  import { AxiosResponse } from 'axios';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'ForgotPassword',
    components: {},
    mixins: [],
  })
  export default class ForgotPassword extends Vue {
    @State('user', { namespace: 'auth' }) form!: UserModel;
    @Action('forgotPassword', { namespace: 'auth' }) forgotPassword!: (params?: FormData) => Promise<AxiosResponse>;

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }

    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
      this.forgotPassword(new FormData(ev.target as HTMLFormElement)).then(() => {
        this.$router.push({name: 'login'})
      });
    }
  }
